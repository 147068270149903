import { UserActions } from '../constants/login'
import { IUserStore } from '../store'

const initialState: IUserStore = {
  data: undefined,
  isLoading: false,
  error: undefined
}

export default function user(
  state: IUserStore = initialState,
  { type, payload }: { type: UserActions; payload: string }
): IUserStore {
  switch (type) {
    case UserActions.USER_LOGGING_IN:
      return { ...initialState, isLoading: true }
    case UserActions.USER_LOGGED_IN:
      return { data: payload, isLoading: false, error: undefined }
    case UserActions.USER_LOGIN_ERROR:
      return { ...initialState, isLoading: false, error: payload }
    case UserActions.USER_LOGGED_OUT:
      return initialState
    default:
      return state
  }
}
