import urljoin from 'url-join'
import Axios, { AxiosResponse } from 'axios'
import { takeLatest, put, call } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { container, FETCH } from 'react-redux-fetch'

import loginActions, { ILoginAction } from '../actions/user'
import { UserActions } from '../constants/login'
import config from '../config'

enum STORAGE_KEY {
  TOKEN = 'token'
}

enum REQUEST_HEADER {
  AUTHORIZATION = 'Authorization'
}

function* login(action: ILoginAction): SagaIterator {
  try {
    const resp: AxiosResponse<string> = yield call(
      Axios.post,
      urljoin(String(config.backendUri), '/login'),
      action.payload
    )

    container.registerRequestHeader(REQUEST_HEADER.AUTHORIZATION, `Bearer ${resp.data}`)
    window.localStorage.setItem(STORAGE_KEY.TOKEN, resp.data)
    yield put(loginActions.loginSuccess(resp.data))
  } catch (error) {
    yield put(loginActions.loginError(error.message))
  }
}

// any here, ts wont compile this if we hint action as PromiseState
function* handleUnauthorizedResponse(action: any): SagaIterator {
  if (action.request && action.request.meta && action.request.meta.response.status === 401) {
    yield put(loginActions.logout())
  }
}

function* restoreToken(): SagaIterator {
  const token: string | null = window.localStorage.getItem(STORAGE_KEY.TOKEN)
  if (!token) {
    return
  }
  container.registerRequestHeader(REQUEST_HEADER.AUTHORIZATION, `Bearer ${token}`)

  yield put(loginActions.loginSuccess(token))
}

function logout(): void {
  window.localStorage.removeItem(STORAGE_KEY.TOKEN)
  container.registerRequestHeader(REQUEST_HEADER.AUTHORIZATION, '')
}

export default function* (): SagaIterator {
  yield takeLatest(UserActions.USER_LOGGING_IN, login)
  yield takeLatest(UserActions.USER_LOGGED_OUT, logout)
  yield takeLatest(UserActions.USER_RESTORE_TOKEN, restoreToken)
  yield takeLatest(FETCH.for('get').REJECT, handleUnauthorizedResponse)
}
