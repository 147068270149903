import React from 'react'
import { Route, Redirect, Switch } from 'react-router'
// globally set moment locale
// tslint:disable-next-line:no-import-side-effect
import 'moment/locale/ru'

import { userIsNotAuthenticatedRedir, userIsAuthenticatedRedir } from '../auth'

// tslint:disable-next-line:no-import-side-effect
import './App.css'
import Login from './Login'
import Receipts from './Receipts'

const LoginComponent = userIsNotAuthenticatedRedir(Login)
const ReceiptsComponent = userIsAuthenticatedRedir(Receipts as any)

const App: React.SFC = () => (
    <Switch>
      <Route path="/login" component={LoginComponent} />
      <Route path="/receipts" component={ReceiptsComponent} />
      <Redirect exact path="/" to="/receipts" />
    </Switch>
  )

export default App
