import { Action } from 'redux'

import { UserActions } from '../constants/login'

export interface ILoginAction extends Action {
  readonly type: UserActions.USER_LOGGING_IN
  readonly payload: { login: string; password: string }
}

export interface ILoginSuccessAction extends Action {
  readonly type: UserActions.USER_LOGGED_IN
  readonly payload: { token: string }
}

export interface ILoginErrorAction extends Action {
  readonly type: UserActions.USER_LOGIN_ERROR
  readonly payload: string
}

export interface ILogoutAction extends Action {
  readonly type: UserActions.USER_LOGGED_OUT
}

export interface IUserRestoreToken extends Action {
  readonly type: UserActions.USER_RESTORE_TOKEN
}

export default {
  login: (login: string, password: string): ILoginAction => ({
    type: UserActions.USER_LOGGING_IN,
    payload: {
      login,
      password
    }
  }),
  loginSuccess: (token: string): ILoginSuccessAction => ({
    type: UserActions.USER_LOGGED_IN,
    payload: {
      token
    }
  }),
  loginError: (error: string): ILoginErrorAction => ({
    type: UserActions.USER_LOGIN_ERROR,
    payload: error
  }),
  logout: (): ILogoutAction => ({
    type: UserActions.USER_LOGGED_OUT
  }),
  restoreToken: (): IUserRestoreToken => ({
    type: UserActions.USER_RESTORE_TOKEN
  })
}
