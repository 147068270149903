import { createStore, compose, applyMiddleware } from 'redux'
import { middleware as fetchMiddleware, PromiseState } from 'react-redux-fetch'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers/rootReducer'
import sagas from './sagas'

export interface IUserStore {
  data: string | undefined
  isLoading: boolean
  error: string | undefined
}

export interface IStore {
  user: IUserStore,
  repository?: {
    [name: string]: PromiseState;
  }
}

const sagaMiddleware = createSagaMiddleware()

const middlewares = [fetchMiddleware, sagaMiddleware]

const initialState: IStore = {
  user: {
    data: undefined,
    isLoading: false,
    error: undefined
  }
}

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middlewares),
    (window as any).devToolsExtension ? (window as any).devToolsExtension() : (f: Function): Function => f
  )
)

sagaMiddleware.run(sagas)

export default store
