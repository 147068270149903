import React from 'react'
import { PromiseState, ReduxFetch } from 'react-redux-fetch'
import { Input, Select, DatePicker } from 'antd'
import moment from 'moment'
import locale from 'antd/lib/date-picker/locale/ru_RU'

import { InputType } from '../types/filters'
import { DATE_FORMAT } from '../constants/date'
import config from '../config'

const { RangePicker } = DatePicker
const { Option } = Select

interface IFetchResult {
  id: number
  name: string
}

type CommonFetchState = PromiseState<IFetchResult[]>

interface IProps {
  cashboxIds: number[]
  organizationIds: number[]
  operatorIds: number[]
  transactionAtFrom: string | undefined
  transactionAtTo: string | undefined
  id: number | undefined
  onChange(name: string, value: InputType): void
}

interface IFetchProps {
  cashboxesFetch: CommonFetchState
  organizationsFetch: CommonFetchState
  operatorsFetch: CommonFetchState
}

const fetchConfig = [
  {
    resource: 'cashboxes',
    request: {
      url: `${config.backendUri}/cashboxes`
    }
  },
  {
    resource: 'organizations',
    request: {
      url: `${config.backendUri}/organizations`
    }
  },
  {
    resource: 'operators',
    request: {
      url: `${config.backendUri}/operators`
    }
  }
]

class Filters extends React.Component<IProps> {
  public handleRangePickerChange = (
    dates: (unknown | moment.Moment[]),
    [transactionAtFrom, transactionAtTo]: [string, string]
  ) => {
    this.props.onChange('transactionAtFrom', transactionAtFrom)
    this.props.onChange('transactionAtTo', transactionAtTo)
  }

  public render = () => {
    const { cashboxIds, organizationIds, operatorIds, transactionAtFrom, transactionAtTo, id, onChange } = this.props

    const rangePickerValue: [moment.Moment, moment.Moment] | undefined = transactionAtFrom && transactionAtTo ?
      [
        moment(transactionAtFrom, DATE_FORMAT),
        moment(transactionAtTo, DATE_FORMAT)
      ] :
    undefined

    return (
      <div className="filters-wrapper">
        <h1>Фильтры</h1>
        <div className="input-wrapper">
          <label>По идентификатору</label>
          <Input
            value={id}
            onChange={onChange.bind(undefined, 'id')}
          />
        </div>
        <div className="input-wrapper">
          <label>По дате транзакции</label>
          <RangePicker
            locale={locale}
            value={rangePickerValue}
            onChange={this.handleRangePickerChange}
            ranges={{
              Сегодня: [moment(), moment()],
              'Этот месяц': [
                moment()
                  .startOf('month'),
                moment()
                  .endOf('month')
              ]
            }}
          />
        </div>
        <ReduxFetch
          config={fetchConfig}
          fetchOnMount
        >
          {({ cashboxesFetch, organizationsFetch, operatorsFetch }: IFetchProps) => {
            if (cashboxesFetch.rejected || organizationsFetch.rejected || operatorsFetch.rejected) {
              return 'Unable to fetch filter data'
            }

            if (cashboxesFetch.value && organizationsFetch.value && operatorsFetch.value) {
              return (
                <>
                  <div className="input-wrapper">
                    <label>По ККТ</label>
                    <Select
                      showSearch
                      value={cashboxIds}
                      mode="multiple"
                      onChange={onChange.bind(undefined, 'cashboxIds')}
                    >
                      {cashboxesFetch.value.map(
                        (value: IFetchResult) => <Option key={value.id} title={value.name} value={value.id}>{value.name}</Option>
                      )}
                    </Select>
                  </div>
                  <div className="input-wrapper">
                    <label>По резиденту</label>
                    <Select
                      showSearch
                      value={organizationIds}
                      mode="multiple"
                      onChange={onChange.bind(undefined, 'organizationIds')}
                    >
                      {organizationsFetch.value.map(
                        (value: IFetchResult) => <Option key={value.id} title={value.name} value={value.id}>{value.name}</Option>
                      )}
                    </Select>
                  </div>
                  <div className="input-wrapper">
                    <label>По кассиру</label>
                    <Select
                      showSearch
                      value={operatorIds}
                      mode="multiple"
                      onChange={onChange.bind(undefined, 'operatorIds')}
                    >
                      {operatorsFetch.value.map(
                        (value: IFetchResult) => <Option key={value.id} title={value.name} value={value.id}>{value.name}</Option>
                      )}
                    </Select>
                  </div>
                </>
              )
            }

            return 'Загрузка...'
          }}
        </ReduxFetch>
      </div>
    )
  }
}

export default Filters
