import { all, fork } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'

import loginSaga from './login'

export default function* sagas(): SagaIterator {
  yield all([
    fork(loginSaga)
  ])
}
