import React from 'react'
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history3/redirect'
import { connect } from 'react-redux'
import { Form, Button, Input, Icon, Card, Alert } from 'antd'
import { Dispatch } from 'redux'

import { UserActions } from '../constants/login'
import { IStore } from '../store'

type LoginParams = {
  login: string;
  password: string;
}

interface IProps extends InjectedAuthRouterProps {
  login(data: LoginParams): void
  restoreToken(): void
  error?: string
}

type State = LoginParams

class Login extends React.Component<IProps, State> {
  public state: State = {
    login: '',
    password: ''
  }

  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    this.props.login({ login: this.state.login, password: this.state.password })
  }

  public handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const name = event.target.name
    this.setState({ [name]: value } as any)
  }

  public componentDidMount = () => {
    this.props.restoreToken()
  }

  public render = () => (
    <Card title="ОФД" className="login-form">
      {this.props.error && <Alert message={this.props.error} type="error"/>}
      <Form onSubmit={this.handleSubmit}>
        <Form.Item>
          <Input
            name="login"
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Логин"
            onChange={this.handleInputChange}
          />
        </Form.Item>
        <Form.Item>
          <Input
            name="password"
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Пароль"
            onChange={this.handleInputChange}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Войти
        </Button>
      </Form>
    </Card>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    login: (payload: LoginParams) => { dispatch({ type: UserActions.USER_LOGGING_IN, payload }) },
    restoreToken: () => { dispatch({ type: UserActions.USER_RESTORE_TOKEN }) }
  }
}

const mapStateToProps = (state: IStore) => ({
  error: state.user.error
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
