import { combineReducers } from 'redux'
import { reducer as fetchReducer } from 'react-redux-fetch'

import user from './user'

const rootReducer = combineReducers({
  user,
  repository: fetchReducer
})

export default rootReducer
