import React from 'react'
import { Layout } from 'antd'
import { InfinityTable } from 'antd-table-infinity'
import connect, { PromiseState } from 'react-redux-fetch'
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history3/redirect'
import queryString from 'query-string'
import { Debounce } from 'lodash-decorators'
import { RouteChildrenProps } from 'react-router'
import locale from 'antd/lib/date-picker/locale/ru_RU'

import { IReceipt } from '../types/models'
import { InputType } from '../types/filters'
import config from '../config'
import { RECEIPT_SORT, SORT_ORDER } from '../constants/receipts'

import Filters from './Filters'
import { columns } from './columns'

const { Content, Sider } = Layout

type ReceiptsQueryParams = {
  limit: number;
  offset?: number;
  cashboxIds: number[];
  organizationIds: number[];
  operatorIds: number[];
  sortOrder: SORT_ORDER;
  sortColumn: RECEIPT_SORT;
}

interface IProps extends InjectedAuthRouterProps, RouteChildrenProps {
  dispatchReceiptsGet(queryParams: ReceiptsQueryParams): void
  receiptsFetch: PromiseState
}

type FiltersState = {
  cashboxIds: number[];
  organizationIds: number[];
  operatorIds: number[];
  id: number | undefined;
  transactionAtFrom: string | undefined;
  transactionAtTo: string | undefined;
}

type State = {
  filters: FiltersState;
  data: IReceipt[];
}

function isEvent(value: InputType): value is React.ChangeEvent<HTMLInputElement> {
  return (value as React.ChangeEvent<HTMLInputElement>).target !== undefined
}

const DEFAULT_LIMIT: number = 30
const DEFAULT_DEBOUNCE_TIMER: number = 300

class Receipts extends React.Component<IProps, State> {
  public state: State = {
    filters: {
      cashboxIds: [],
      organizationIds: [],
      operatorIds: [],
      id: undefined,
      transactionAtFrom: undefined,
      transactionAtTo: undefined
    },
    data: []
  }

  public handleFilterChange = (name: string, value: InputType) => {
    const realValue = isEvent(value) ? value.target.value : value
    this.setFilterValue(name, realValue)
  }

  public setFilterValue = (name: string, value: InputType) => {
    this.setState((state: State) => ({
      filters: {
        ...state.filters,
        [name]: value || undefined
      }
    }))
  }

  public handleAppendReceipts = (data: IReceipt[]) => {
    this.setState((state: State) => ({ data: [...state.data, ...data] }))
  }

  public generateQuery = () => queryString.stringify(this.state.filters)

  public handleFetchData = () => {
    const { filters, data } = this.state

    this.props.dispatchReceiptsGet({
      ...filters,
      offset: data.length,
      limit: DEFAULT_LIMIT,
      sortColumn: RECEIPT_SORT.TRANSACTION_AT,
      sortOrder: SORT_ORDER.DESC
    })
  }

  // cant use decorators with arrow functions
  @Debounce(DEFAULT_DEBOUNCE_TIMER)
  public clearPage(): void {
    this.setState(
      { data: [] },
      () => { this.handleFetchData() }
    )
  }

  public componentDidUpdate = (nextProps: IProps, nextState: State) => {
    if (
      nextState.filters.cashboxIds !== this.state.filters.cashboxIds ||
      nextState.filters.organizationIds !== this.state.filters.organizationIds ||
      nextState.filters.operatorIds !== this.state.filters.operatorIds ||
      nextState.filters.transactionAtFrom !== this.state.filters.transactionAtFrom ||
      nextState.filters.transactionAtTo !== this.state.filters.transactionAtTo ||
      nextState.filters.id !== this.state.filters.id
    ) {
      this.props.history.push(`/receipts#${this.generateQuery()}`)
      this.clearPage()
    }
  }

  public componentWillMount = () => {
    this.setState({
      filters: {
        ...this.state.filters,
        ...queryString.parse(this.props.location.hash, { parseNumbers: true })
      }
    })
  }

  public componentWillReceiveProps = (nextProps: IProps) => {
    if (this.props.receiptsFetch.pending && nextProps.receiptsFetch.fulfilled) {
      this.handleAppendReceipts(nextProps.receiptsFetch.value)
    }
  }

  public render = () => {
    const { filters, data } = this.state
    const { receiptsFetch: { pending: loading } } = this.props

    return (
      <Layout>
        <Sider theme="light" width="350">
          <Filters
            {...filters}
            onChange={this.handleFilterChange}
          />
        </Sider>
        <Content>
          <InfinityTable
            rowKey="id"
            loading={loading}
            onFetch={this.handleFetchData}
            pageSize={30}
            columns={columns}
            scroll={{ y: 'calc(100vh - 80px)' }}
            dataSource={data}
            locale={locale}
            bordered
          />
        </Content>
      </Layout>
    )
  }
}

export default connect(
  [
    {
      resource: 'receipts',
      request: (queryParams: ReceiptsQueryParams) => {
        const query = queryString.stringify(queryParams)

        return {
          url: `${config.backendUri}/receipts?${query}`,
          comparison: query
        }
      }
    }
  ]
)(Receipts)
