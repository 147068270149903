import React from 'react'
import moment from 'moment'

import { IOrganization, ICashbox, IOperator, IItem } from '../types/models'

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 100
  },
  {
    title: 'Резидент',
    dataIndex: 'organization',
    key: 'organization',
    render: (value: IOrganization) => value.name,
    width: 200
  },
  {
    title: 'Рег. номер ККТ',
    dataIndex: 'cashbox',
    key: 'cashbox',
    render: (value: ICashbox) => value.name,
    width: 150
  },
  {
    title: 'Кассир',
    dataIndex: 'operator',
    key: 'operator',
    render: (value: IOperator) => value.name,
    width: 150
  },
  {
    title: 'Порядковый номер чека',
    dataIndex: 'receiptNumber',
    key: 'receiptNumber',
    width: 130
  },
  {
    title: 'Дата и время транзакции',
    dataIndex: 'transactionAt',
    key: 'transactionAt',
    width: 160,
    render: (value: string) => moment(value)
      .format('DD.MM.YYYY HH:mm')
  },
  {
    title: 'Состав транзакции',
    dataIndex: 'items',
    key: 'items',
    render: (value: IItem[]) => value.map((v: IItem) => <div key={v.name}>{v.quantity}x | {v.name} | {v.price / 100}Р</div>)
  },
  {
    title: 'Оплачено наличкой',
    dataIndex: 'eCashSum',
    key: 'eCashSum',
    width: 120,
    render: (value: number) => `${value / 100}Р`
  },
  {
    title: 'Оплачено картой',
    dataIndex: 'cashSum',
    key: 'cashSum',
    width: 120,
    render: (value: number) => `${value / 100}Р`
  },
  {
    title: 'Итог',
    dataIndex: 'totalSum',
    key: 'totalSum',
    width: 120,
    render: (value: number) => `${value / 100}Р`
  }
]
